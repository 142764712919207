@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://blog.millestudios.com/wp-includes/css/dist/block-library/style.min.css");

@layer components {
  .header-link {
    @apply text-white/70 hover:text-white py-3 px-2;
  }
  .header-link.active {
    @apply text-orange-600/80 hover:text-orange-600;
  }
  .btn-outline-white {
    @apply border border-white leading-tight px-3 py-2 hover:bg-white hover:text-black transition-colors;
  }
  .btn-music-control {
    @apply rounded-full w-9 h-9 leading-none
    flex justify-center items-center
    transition-colors
    hover:bg-white/30
    disabled:bg-transparent
    disabled:text-gray-500;
  }
  .link {
    @apply text-purple-100 hover:text-purple-300;
  }
}

@layer base {
  body {
    @apply bg-black overflow-y-scroll;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mt-2 mb-1;
  }
  h1 {
    @apply text-5xl;
  }
  h2 {
    @apply text-3xl;
  }
  ul {
    @apply list-disc ml-10;
  }
  ol {
    @apply list-decimal ml-10;
  }
  .WpPage p {
    @apply mt-1 mb-3;
  }
  input[type="text"],
  select,
  textarea {
    @apply border rounded-sm bg-purple-100 text-gray-800 px-2 py-1 focus:border-yellow-500 focus:bg-yellow-100 focus-visible:outline-none;
  }
}
